//Generate header file for C/C++
// Defines list of constants for each image and its address in bin file
class HeaderFile {
    readonly START_ADDRESS = 0x90000000;
    public file_list: { name: string, size: number }[]= [];
    addImage(name: string, size: number): void {
        //prepare name for C/C++ constant
        name = name.toUpperCase().replace(/[^A-Z0-9_]/g, '_');
        console.log(`Adding "${name}" (${size}b) to header files list`);
        this.file_list.push({ name, size });
    }
    async generateHeader(): Promise<string> {
        let header = '#ifndef IMAGE_LIST_HEADER_FILE\n#define IMAGE_LIST_HEADER_FILE\n\n';
        let address = this.START_ADDRESS;
        for (let file of this.file_list) {
            const address_hex = address.toString(16);
            console.log(`Adding ${file.name} at 0x${address_hex}; size: ${file.size}b`);
            console.log(`Size: ${file.size}`);

            header += `#define IMG_${file.name} 0x${address_hex}\n`;
            header += `#define IMG_${file.name}_SIZE ${file.size}\n`;

            address += file.size;
        }
        header += '\n#endif\n';
        return header;
    }
}
export { HeaderFile }
